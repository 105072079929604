import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Alert } from './components/Alert';
import { YouTube } from './components/YouTube';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "adding-to-the-waiting-list---staff"
    }}>{`Adding to the Waiting List - Staff`}</h1>
    <p>{`When an event is fully booked, members can be added to a waiting list. When they're on the waiting list, they will be notified if tickets become available so they can book their space. The staff process for adding members to an event's waiting list is similar to how it is for members to add themselves. In order to join a waiting list, members will need to provide an email address as this is the only way the system notifies them of their available space.`}</p>
    <p>{`If the member does not claim the ticket within the timeframe allocated, their space will become available to the next person on the waiting list. The duration of this availabily window can be set within the Advanced settings by an Admin.`}</p>
    <p>{`It's important to note that the system will wait for the required number of tickets for the first person in the queue to be available before notifing them of a vacancy. This can sometimes result in an event that doesn't reach capacity. If the next person on the waiting list requires two tickets, the platform will wait for two tickets to become available, even if the second person waiting requires only one ticket. Staff can set how many waiting list places a single member can occupy on the Advanced page.`}</p>
    <p>{`The waiting list functionality runs automatically in the background every 10 minutes. So, once space becomes available, it may take up to 10 minutes for the next in queue to be notified.`}</p>
    <p>{`Staff can edit the number of places required by a person on the waiting list using the blue pencil icon on an event's page, or they can edit the event's booking capacity if they wish to make more places available. Both of these will reduce the size of an event's waiting list.`}</p>
    <h2 {...{
      "id": "waiting-lists"
    }}>{`Waiting Lists`}</h2>
    <p>{`Once an event is fully booked you can add a member to the waiting list`}</p>
    <ol>
      <li parentName="ol">{`Select event`}</li>
      <li parentName="ol">{`Click the blue '+' to add someone to the waiting list`}</li>
      <li parentName="ol">{`Enter the persons 'Name', the 'Number of Places' and the 'Card Number'`}</li>
      <li parentName="ol">{`If they have an email address in their account this will automatically fill`}</li>
      <li parentName="ol">{`Click 'Add to Waiting List'`}</li>
      <li parentName="ol">{`Click 'Close'`}</li>
      <li parentName="ol">{`Waiting list notifications are automated if cancellations occur`}</li>
      <li parentName="ol">{`Members who are on the waiting list will be notified via email if a space becomes available`}</li>
      <li parentName="ol">{`They are given 24 hours (this can be altered in Advanced settings) to make their bookings before the ticket then moves on to the next person in the waiting list`}</li>
      <li parentName="ol">{`Only library members can be on the waiting list as they need their library card number and PIN to claim their ticket`}</li>
      <li parentName="ol">{`It's important to note that if the first person on the waiting list requires more tickets than are available they will not be notified unless more positions become available, this can sometimes result in an event that doesn't reach capacity and staff may wish to utilise the overbook function`}</li>
    </ol>
    <h3 {...{
      "id": "watch-the-video"
    }}>{`Watch The Video`}</h3>
    <YouTube mdxType="YouTube"><iframe src="//www.youtube.com/embed/Ld4Soa9Uovo" frameBorder="0" allowFullScreen style={{
        "position": "absolute",
        "top": "0",
        "left": "0",
        "width": "100%",
        "height": "100%"
      }}></iframe></YouTube>
    <Alert kind="info" mdxType="Alert">You can check the status of a person on the waiting list, it will say Claimed, Waiting or Expired.</Alert>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      